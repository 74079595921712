/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
SPDX-License-Identifier: Apache-2.0
*/
@use '~@cloudscape-design/design-tokens' as cs;

#back-button {
  margin-top: 20px;
}

.flex-parent {
  display: flex;
  scroll-behavior: smooth; // Add smooth scrolling to the parent container
}

.flex-child {
  padding: 10px;
  width: 50%;
}

.product-page-aside {
  grid-row: 2;
  grid-column: 2 / 3;
  padding-inline-start: calc(#{cs.$space-scaled-xxxl} / 2);
}

.product-page-aside-sticky {
  position: sticky;
  top: 20px;
}

.product-page-content-grid {
  display: grid;
  grid-template-columns: 10fr 2fr;
  grid-template-rows: 0 auto 0;
  margin-block-start: cs.$space-static-xxl;
}

.product-page-content {
  grid-row: 2;
  grid-column: 1 / 2;
  padding-inline-end: calc(#{cs.$space-scaled-xxxl} / 2);
}

hr {
  width: 100%;
  height: 0;
  border: none;
  border-block-start: 1px solid cs.$color-border-divider-default;
}
